/*
 * @Descripttion: 材机库
 * @Author: renmingming
 * @Date: 2022-08-24 16:05:49
 * @LastEditors: renmingming
 * @LastEditTime: 2022-09-09 15:02:38
 */
import http from '@/utils/http';
import { TreeType, MaterialType } from '@/interface/materialMachineLibrary';
import { QyStlBillDeType } from '@/interface/costQuotaLibrary'

/**
 * 全量材机库分类
 * @returns 
 */
export function stlMaterialClassLevel() {
  return http.get<TreeType[]>('/cocc-quotalibrary-resource/1/stlMaterialClasslevel')
}

/**
 * 全量对应列表
 * @returns 
 */
export function getAdsQycbRcj(params) {
  return http.get<{list: MaterialType[], total: number}>('/cocc-quotalibrary-resource/1/adsQycbRcj', params)
}

/**
 * 更新
 * @param params 
 * @returns 
 */
export function updateQyStlRcj(params) {
  return http.post('/cocc-quotalibrary-resource/1/qyStlRcj', params)
}
type PageDataType = {
  total: number;
  list: MaterialType[]
}

type historyType = {
  avgPrice: number;
  pageData: PageDataType
}
/**
 * 历史材机列表
 * @returns 
 */
export function getMaterialHistory(params) {
  return http.get<historyType>('/cocc-quotalibrary-resource/1/qyStlRcjHistory/history', params)
}

/**
 * 更新企业材机历史数据
 * @param params 
 * @returns 
 */
export function updateHistory(params) {
  return http.put<boolean>('/cocc-quotalibrary-resource/1/qyStlRcjHistory', params)
}

/**
 * 查询材机库详情列表新增材价中的市场含税价格
 * @param materialCode 
 * @returns 
 */
export function getMarketPrice(materialCode) {
  return http.get<{marketPrice: number}>('/cocc-quotalibrary-resource/1/adsQycbRcj/market/price', {materialCode})
}

/**
 * 详情里新增
 * @param params 
 * @returns 
 */
export function postDetailAdd(params) {
  return http.post<boolean>('/cocc-quotalibrary-resource/1/qyStlRcj/history/detail/add', params)
}

/**
 * 删除历史数据
 * @returns 
 */
export function deleteHistory(id, serialNumber) {
  return http.delete<boolean>(`/cocc-quotalibrary-resource/1/qyStlRcjHistory/id?id=${id}&serialNumber=${serialNumber}`)
}

/**
 * 
 * @param params 查询企业分类
 * @returns 
 */
export function getQyLevelAll(params) {
  return http.get<TreeType[]>('/cocc-quotalibrary-resource/1/stlMaterialClasslevel/queryQyLevelAll', params)
}

/**
 * 分页查询企业下的全量材料数据
 * @param params 
 * @returns 
 */
export function getQyList(params) {
  return http.get<PageDataType>('/cocc-quotalibrary-resource/1/qyStlRcj/queryQyByPage', params)
}

type MaterialListType = {
  intelligentRCJDetails: MaterialType[];
  quotaRCJList:  QyStlBillDeType[]
}
/**
 * 智能推荐人材机明细列表
 * @param deCodeList 
 * @returns 
 */
export function materialList(quotaIdList, agencyCode) {
  return http.post<MaterialListType>(`/cocc-quotalibrary-resource/1/adsQycbRcj/intelligent/details?agencyCode=${agencyCode}`, quotaIdList)
}